import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/common.less'
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/element-ui.less'
import * as Echarts from 'echarts'
import '@/icons'
import '@/directives/el-draggable-dialog'
import '@/directives/el-fit-content'

const baseURL = 'https://api.saas.famesmart.com'
Vue.config.productionTip = false
Vue.filter('getImg', uuid => {
  if (!uuid) {
    return ''
  }
  if (uuid && uuid.startsWith('http')) {
    return uuid
  }
  return `${baseURL}/resident-manager/download_self?uuid=${uuid}`
})
Vue.use(elementUI)
Vue.prototype.$echarts = Echarts
Vue.prototype.$host = 'http://api.phorai.com'
Vue.prototype.$mainColor = '#e1ebff'
Vue.prototype.$colorSet = ['#FFD24A', '#3DCBF1', '#EC6666', '#44A5FF']
Vue.prototype.$eleDateFormat = 'yyyy-MM-dd'
Vue.prototype.$dateFormat = 'YYYY-MM-DD'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
