export const COORD = {
  '121.365659, 31.027635': [[-10128.318746370649, -22817.81423975357]],
  '121.387014, 31.020611': [[-8092.437022342838, -23600.23314756219]],
  '121.38666, 31.02051': [[-8126.219279104426, -23611.373878068578]],
  '121.378717, 31.019524': [[-8883.992684401384, -23719.326814562955]],
  '121.388248, 31.022376': [[-7974.570452042646, -23404.834697978666]],
  '121.379179, 31.018927': [[-8839.977177633084, -23785.560988065918]],
  '121.391691, 31.022461': [[-7646.028496274894, -23395.850362813733]],
  '121.39283752, 31.03017616': [[-7536.092150924714, -22541.107502649756]]
}

export const COMMUNITY_AREA = [
  {
    label: '飞碟苑',
    data: [
      [-7324.696947793012, -22854.78988896571],
      [-7551.788305387011, -22943.841610067306],
      [-7701.760658608096, -22511.935589019246],
      [-7339.120386803614, -22370.620312337694],
      [-7212.621559243531, -22605.739344951235],
      [-7310.530807524831, -22640.591889676507],
      [-7350.917124424366, -22557.445386054605],
      [-7411.887940195991, -22570.294554611173],
      [-7324.696947793012, -22854.78988896571]
    ]
  },
  {
    label: '夏朵园',
    data: [
      [-7480.084069811255, -22932.74416090864],
      [-7298.916509265721, -22872.397963310974],
      [-7276.227485206532, -22906.33313133274],
      [-7223.554416862365, -22882.84676247105],
      [-7231.492315255875, -22851.203513147644],
      [-7120.38929032808, -22822.480389359458],
      [-6920.5270165757065, -23259.37221312523],
      [-6974.588265962613, -23280.602780927133],
      [-7019.559898046742, -23272.818961917583],
      [-7177.688113744339, -23349.796786129165],
      [-7331.610712094075, -23400.225531930882],
      [-7358.170803300479, -23346.391264296995],
      [-7731.8091425211305, -23482.843191837845],
      [-7837.0826245335475, -23166.79830648416],
      [-7747.890465562276, -23126.554409211327],
      [-7480.084069811255, -22932.74416090864]
    ]
  },
  {
    label: '西中街',
    data: [
      [-10159.844373143409, -22743.408800779358],
      [-9803.508781340057, -22593.60167150575],
      [-9479.29709884085, -22485.46804212242],
      [-9426.19193159418, -22609.814049578992],
      [-9423.261602545681, -22757.45174243676],
      [-9642.138836562433, -22806.605674205544],
      [-10124.62647166894, -22882.165841513503],
      [-10159.844373143409, -22743.408800779358]
    ]
  },
  {
    label: '敬南苑',
    data: [
      [-10045.333287494503, -22297.391129558135],
      [-10018.747959870188, -22434.13084148268],
      [-9972.551725017345, -22415.900758486074],
      [-9959.608760739065, -22402.552256944164],
      [-9821.223283522402, -22367.31648535036],
      [-9844.262060672785, -22254.449746818686],
      [-10045.333287494503, -22297.391129558135]
    ]
  },
  {
    label: '保利佳苑',
    data: [
      [-8977.924631712034, -23631.731149468273],
      [-8897.464219954602, -23840.975075259186],
      [-8830.609293892232, -23813.03898470745],
      [-8727.593845922536, -23716.616034644725],
      [-8786.76252634113, -23555.746231815814],
      [-8977.924631712034, -23631.731149468273]
    ]
  },
  {
    label: '乐康苑',
    data: [
      [-8387.685843131045, -22793.12770404497],
      [-8031.597943339112, -22659.983428838386],
      [-7937.279194333515, -22939.229746788744],
      [-8028.267329482077, -22999.10653043428],
      [-8042.392007121727, -23037.111955170265],
      [-8315.153304631514, -23114.900528756705],
      [-8389.257219177018, -22794.912948457037],
      [-8387.685843131045, -22793.12770404497]
    ]
  },
  {
    label: '银康苑',
    data: [
      [-8214.536238417122, -23445.670862245417],
      [-7821.163084169089, -23307.586474726148],
      [-7757.096759266704, -23514.406685289996],
      [-8152.938953824252, -23647.214657937802],
      [-8214.536238417122, -23445.670862245417]
    ]
  },
  {
    label: '银春苑',
    data: [
      [-8102.493970245626, -23130.723696692294],
      [-7903.161384417183, -23067.23685319725],
      [-7821.707740476332, -23297.173404574867],
      [-8032.717019228723, -23365.56769616824],
      [-8102.493970245626, -23130.723696692294]
    ]
  },
  {
    label: '景城馨苑',
    data: [
      [-9838.326394078495, -23896.01782241361],
      [-9580.45638856332, -23818.858731962246],
      [-9512.99124584176, -24036.485236748536],
      [-9761.098420227874, -24106.273151736554],
      [-9838.326394078495, -23896.01782241361]
    ]
  },
  {
    label: '保利雅苑',
    data: [
      [-9984.758611997218, -23400.937398949205],
      [-9713.966551427084, -23332.447425994575],
      [-9664.505954932787, -23562.818988793075],
      [-9914.713933059948, -23642.70777430179],
      [-9984.758611997218, -23400.937398949205]
    ]
  },
  {
    label: '景城品雅苑',
    data: [
      [-8902.603518556014, -22997.31168355018],
      [-8635.078270805145, -22894.330016149266],
      [-8518.685349262096, -23237.665037972594],
      [-8781.562052236663, -23305.045490337805],
      [-8902.603518556014, -22997.31168355018]
    ]
  },
  {
    label: '华银坊',
    data: [
      [-7774.508180690847, -23028.02451802377],
      [-7553.3240943998235, -22964.18421475714],
      [-7416.307146772512, -23399.520127517226],
      [-7732.140213871841, -23486.542571553557],
      [-7836.0877988473285, -23162.61846231929],
      [-7745.033244538082, -23130.768650867758],
      [-7774.508180690847, -23028.02451802377]
    ]
  },
  {
    label: '景城和苑',
    data: [
      [-9442.417753241518, -23521.274779717067],
      [-9248.236324949152, -23462.75340717038],
      [-9163.143988063488, -23686.96437525388],
      [-9363.551719583531, -23735.57305222585],
      [-9442.417753241518, -23521.274779717067]
    ]
  }
]
