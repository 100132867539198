import request from '@/utils/request'
import { get } from 'lodash'

export const fetchEventStat = (alarmInfo, range) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/dailyAlarms/eventDealNum',
    method: 'GET',
    params: { alarmInfo, dateStart, dateEnd }
  })
}

export const fetchEventAlarmCount = (alarmInfo, range) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/alarmList/eventWarning',
    method: 'GET',
    params: { alarmInfo, dateStart, dateEnd }
  })
}

export const fetchAlarmSource = (alarmInfo, range) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/dailyAlarms/eventNumByType',
    method: 'GET',
    params: { alarmInfo, dateStart, dateEnd }
  })
}

export const fetchInOutFrequency = range => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/entrance/entranceFrequency',
    method: 'GET',
    params: { dateStart, dateEnd }
  })
}

export const fetchAllCommAlarmCount = (range, no_params = true) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/dailyAlarms/eventNumBySheQu',
    method: 'GET',
    params: { dateStart, dateEnd, no_params }
  })
}

export const fetchCommStatDetail = (commCode, range, no_params = true) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/alarmList/alarmNumByType',
    method: 'GET',
    params: { commCode, dateStart, dateEnd, no_params }
  })
}

export const fetchUrgeTimes = (range, alarmInfo) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/urgent/cuiBanTimes',
    method: 'GET',
    params: { dateStart, dateEnd, alarmInfo }
  })
}

export const fetchAlarmHandleRate = () => {
  return request({
    url: '/dailyAlarms/eventCloseRate',
    method: 'GET'
  })
}

export const fetchCommReward = () => {
  return request({
    url: '/dailyAlarms/resultShow',
    method: 'GET'
  })
}
//获取设备地址
export const fetchDevices = data => {
  return request({
    url: '/devices/getDevices',
    method: 'GET',
    params: data
  })
}
//获取报警类型及点位
export const fetchAlarmType = data => {
  return request({
    url: `${process.env.VUE_APP_BASE_API}/api/new/topAlarm`,
    method: 'POST',
    params: data
  })
}
//报警详情
export const alarmDetails = data => {
  return request({
    url: `${process.env.VUE_APP_BASE_API}/api/new/alarmDetails`,
    method: 'POST',
    params: data
  })
}
//报警催办详情
export const alarmProcess = data => {
  return request({
    url: `${process.env.VUE_APP_BASE_API}/api/urgent/alarmProcess`,
    method: 'POST',
    params: data
  })
}
//获取楼栋地址
export const fetchBuildings = data => {
  return request({
    url: '/buildings/getBuildings',
    method: 'Get',
    params: data
  })
}
//高德坐标转高德坐标
export const turnXY = datas => {
  return request({
    url: 'http://139.224.8.109:30002/coordinates_conversion',
    method: 'POST',
    data: {
      coordinates: [[datas.lng, datas.lat]]
    }
  })
}

export const fetchAlarmInfo = () => {
  return request({
    url: '/categories/listAll',
    method: 'GET'
  })
}

export const fetchAllCommunities = (no_params = true) => {
  return request({
    url: '/communities/listAll',
    method: 'GET',
    params: { no_params }
  })
}

export const fetchEventHandle = (alarmInfo, dateStart, dateEnd) => {
  return request({
    url: '/dailyAlarms/eventDealTotal',
    method: 'GET',
    params: { alarmInfo, dateStart, dateEnd }
  })
}

export const fetchExpiredEvent = (alarmInfo, dateStart, dateEnd) => {
  return request({
    url: '/dailyAlarms/expiredAlarmNum',
    method: 'GET',
    params: { alarmInfo, dateStart, dateEnd }
  })
}

export const fetchAvgEventNum = () => {
  return request({
    url: '/communities/avgEventNum',
    method: 'GET'
  })
}

export const sendSMS = (phone, TemplateParamSet, TemplateID = 1717543) => {
  return request({
    url: 'https://api.saas.famesmart.com/basic-infos/smsSend',
    method: 'POST',
    headers: {
      Accept: 'application/json'
    },
    data: { phone, TemplateParamSet, TemplateID }
  })
}

export const fetchNewCommTypeAnalyze = (range, no_params = true) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/dailyAlarms/alarmByAlarmInfo',
    method: 'GET',
    params: { dateStart, dateEnd, no_params }
  })
}

export const fetchAlarmStatByStatus = (commCode, range, no_params = true) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/dailyAlarms/alarmInfoByCommCode',
    method: 'GET',
    params: { commCode, dateStart, dateEnd, no_params }
  })
}

//收藏列表
export const fetchVedioMarkList = () => {
  return request({
    url: '/vedioMark/listAll',
    method: 'GET'
  })
}
//收藏
export const setVedioMark = params => {
  return request({
    url: '/vedioMark/favourite',
    method: 'POST',
    data: params
  })
}
