import request from '@/utils/request'
import { get, omit } from 'lodash'

export const editCard = subject => {
  return request({
    url: 'http://service-9ro9kz71-1253858861.ap-shanghai.apigateway.myqcloud.com/release/get1DData',
    method: 'POST',
    data: { subject }
  })
}

export const fetchPendingAlarm = (pageNum = 1, alarmType, range, pageSize = 20) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/alarmList/pageList',
    method: 'GET',
    params: { pageNum, alarmType, dateStart, dateEnd, pageSize }
  })
}

export const fetchAlarmAnalyze = (alarmType, range) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/alarmList/eventAnalysis',
    method: 'GET',
    params: { alarmType, dateStart, dateEnd }
  })
}

export const fetchKeyPerson = () => {
  return request({
    url: 'http://10.0.2.46:8000/api/comm/getKeyPerson',
    method: 'GET'
  })
}

export const fetchAlarmDealResult = (date, alarmInfo) => {
  return request({
    url: '/dailyAlarms/dealAlarmRes',
    method: 'GET',
    params: { date, alarmInfo }
  })
}

export const fetchAlarmDayOverview = (date, alarmInfo) => {
  return request({
    url: '/dailyAlarms/eventOverview',
    method: 'GET',
    params: { date, alarmInfo }
  })
}

export const fetchAlarmTotal = () => {
  return request({
    url: '/dailyAlarms/alarmCuiBan',
    method: 'GET'
  })
}

export const fetchAlarmChartData = alarmInfo => {
  return request({
    url: '/dailyAlarms/recentYearEventTrend',
    method: 'GET',
    params: { alarmInfo }
  })
}

export const fetchCommRank = (range, no_params = true) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/dailyAlarms/alarmZhanBi',
    method: 'GET',
    params: { dateStart, dateEnd, no_params }
  })
}

export const fetchUrgeList = params => {
  const dateStart = get(params.range, '[0]')
  const dateEnd = get(params.range, '[1]')
  params = Object.assign({}, params, { dateStart }, { dateEnd })
  params = omit(params, 'range')
  return request({
    url: '/urgentList/pageList',
    method: 'GET',
    params
  })
}

export const fetchUrgeDetail = id => {
  return request({
    url: `/urgentList/${id}`,
    method: 'GET'
  })
}

export const fetchAlarmTypeCount = (alarmInfo, alarmType, range) => {
  const dateStart = get(range, '[0]')
  const dateEnd = get(range, '[1]')
  return request({
    url: '/alarmList/alarmInfoCount',
    method: 'GET',
    params: { alarmInfo, alarmType, dateStart, dateEnd }
  })
}

/**
 * 催办案件处置完成率
 */
export const fetchUrgeRate = () => {
  return request({
    url: '/urgePendingList/urgeDoneRate',
    method: 'GET'
  })
}
