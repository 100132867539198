import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/CommonLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('@/views/LoginPage/index.vue')
  },
  {
    path: '/',
    redirect: '/community',
    component: Layout,
    children: [
      {
        path: '/community',
        name: 'CommunityView',
        components: {
          leftOne: () => import('@/views/Community/LeftComponents/MsgNotice.vue'),
          leftTwo: () => import('@/views/Community/LeftComponents/EventHandle.vue'),
          leftThree: () => import('@/views/Community/LeftComponents/GridEventTotal.vue'),
          leftSmallOne: () => import('@/views/Community/RightComponents/EventSource.vue'),
          leftSmallTwo: () => import('@/views/Community/RightComponents/ManagementResult.vue'),
          rightOne: () => import('@/views/Community/RightComponents/AlarmTotal.vue'),
          rightTwo: () => import('@/views/Community/RightComponents/EventHandleRate.vue'),
          rightThree: () => import('@/views/Community/RightComponents/ExpiredEventAnalyze.vue'),
          rightSmallOne: () => import('@/views/Community/LeftComponents/InOutFrequency.vue'),
          rightSmallTwo: () => import('@/views/Community/LeftComponents/GridUrgeTimes.vue')
        }
      },
      {
        path: '/alarm',
        name: 'AlarmView',
        components: {
          leftOne: () => import('@/views/Alarm/LeftComponents/AlarmOverview.vue'),
          leftTwo: () => import('@/views/Alarm/LeftComponents/ToDoEvent.vue'),
          leftThree: () => import('@/views/Alarm/LeftComponents/KeyPerson.vue'),
          // leftTwo: () => import('@/views/Alarm/LeftComponents/AlarmAnalyze.vue'),
          // leftThree: () => import('@/views/Alarm/LeftComponents/ToDoEvent.vue'),
          leftSmallOne: () => import('@/views/Alarm/RightComponents/UrgeCount.vue'),
          leftSmallTwo: () => import('@/views/Alarm/RightComponents/UrgeUndispatch.vue'),
          leftSmallThree: () => import('@/views/Alarm/RightComponents/UrgeUnsolved.vue'),
          // leftSmallOne: () => import('@/views/Alarm/LeftComponents/AlarmHandle.vue'),
          // leftSmallTwo: () => import('@/views/Alarm/LeftComponents/KeyPerson.vue'),
          rightOne: () => import('@/views/Alarm/RightComponents/EventDistribution.vue'),
          rightTwo: () => import('@/views/Alarm/RightComponents/RankView.vue'),
          rightThree: () => import('@/views/Alarm/LeftComponents/AlarmAnalyze.vue'),
          rightSmallOne: () => import('@/views/Alarm/LeftComponents/AlarmHandle.vue'),
          rightSmallTwo: () => import('@/views/Alarm/RightComponents/AlarmNumTrend.vue')
        }
      },
      {
        path: '/epidemic',
        name: 'EpidemicView',
        components: {
          leftOne: () => import('@/views/Epidemic/LeftComponents/EpidemicManagement.vue'),
          leftTwo: () => import('@/views/Epidemic/LeftComponents/NucleinExpired.vue'),
          leftSmallOne: () => import('@/views/Epidemic/LeftComponents/AlarmStat.vue'),
          rightOne: () => import('@/views/Epidemic/RightComponents/DeployOverview.vue'),
          rightTwo: () => import('@/views/Epidemic/RightComponents/CommunityDevice.vue'),
          rightSmallOne: () => import('@/views/Epidemic/RightComponents/VideoView.vue')
        }
      },
      {
        path: '/rubbish',
        name: 'RubbishView',
        components: {
          leftOne: () => import('@/views/Rubbish/LeftComponents/RubbishUsage.vue'),
          leftTwo: () => import('@/views/Rubbish/LeftComponents/RubbishUsageDaily.vue'),
          leftSmallOne: () => import('@/views/Rubbish/LeftComponents/RubbishCateTrend.vue'),
          rightOne: () => import('@/views/Rubbish/RightComponents/ResidentRubbish.vue'),
          rightTwo: () => import('@/views/Rubbish/RightComponents/AdminRubbish.vue'),
          rightSmallOne: () => import('@/views/Rubbish/RightComponents/DepartRubbish.vue'),
          rightSmallTwo: () => import('@/views/Rubbish/RightComponents/BoutiqueRubbish.vue')
        }
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = sessionStorage.getItem('saas-token')
  console.log('gnmddddddddd: ', token)
  if (!token && to.path !== '/login') {
    next({ path: '/login', replace: true })
  } else if (token && to.path === '/login') {
    next({ path: '/', replace: true })
  } else {
    next()
  }
})

export default router
