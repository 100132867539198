<template>
  <div class="layout" :class="$route.name">
    <div class="layout-map">
      <keep-alive include="MapPart">
        <component :is="current" />
      </keep-alive>
    </div>
    <div class="layout-header">
      <div class="time-wrap left">
        <span class="date-s">{{ time.date }}</span>
        <span>{{ time.week }}</span>
        <span class="time-s">{{ time.time }}</span>
      </div>
      <div class="title-wrap full-bg">
        <Curve />
        <h2 class="title">马桥镇智联社区平台</h2>
        <Curve :reverse="true" />
      </div>
      <div class="time-wrap right">
        <span class="date-s">多云</span>
        <span>空气质量 优</span>
        <span class="time-s">PM2.5 28</span>
      </div>
      <el-select
        v-model="commCode"
        class="layout-header__drop common"
        popper-class="header-select"
        @change="updateComm">
        <template slot="prefix"><svg-icon icon-class="location" /></template>
        <el-option
          v-for="item in $store.state.commList"
          :key="item.commCode"
          :label="item.commName"
          :value="item.commCode" />
      </el-select>
    </div>
    <transition name="moveLeft">
      <div v-if="slideIn" class="slider left" :class="{ slideIn: slideIn }">
        <div class="slider-big full-bg">
          <div class="big-frame-inner left">
            <SideBar />
            <router-view name="leftOne" ref="leftOne" />
            <router-view name="leftTwo" ref="leftTwo" />
            <router-view name="leftThree" ref="leftThree" />
          </div>
        </div>
        <div class="slider-small full-bg" :class="{ expended: leftExpend }">
          <div class="small-frame-inner left">
            <router-view name="leftSmallOne" ref="leftSmallOne" />
            <router-view name="leftSmallTwo" ref="leftSmallTwo" />
            <router-view name="leftSmallThree" ref="leftSmallThree" />
          </div>
        </div>
        <SliderButton @update="updateLeft" :outerExpended="leftExpend" />
      </div>
    </transition>
    <transition name="moveRight">
      <div v-if="slideIn" class="slider right" :class="{ slideIn: slideIn }">
        <div class="slider-big full-bg">
          <div class="big-frame-inner right">
            <SideBar />
            <router-view name="rightOne" ref="rightOne" />
            <router-view name="rightTwo" ref="rightTwo" />
            <router-view name="rightThree" ref="rightThree" />
          </div>
        </div>
        <div class="slider-small full-bg" :class="{ expended: rightExpended }">
          <div class="small-frame-inner right">
            <router-view name="rightSmallOne" ref="rightSmallOne" />
            <router-view name="rightSmallTwo" ref="rightSmallTwo" />
            <router-view name="rightSmallThree" ref="rightSmallThree" />
          </div>
        </div>
        <SliderButton @update="updateRight" :outerExpended="rightExpended" />
      </div>
    </transition>
    <div class="layout-bottom full-bg" :class="{ expended: bottomExpended }">
      <SliderButton @update="updateBottom" />
      <div
        v-for="item in PATH"
        :key="`lybtn${item.name}`"
        class="btn-item full-bg"
        :class="{ active: item.name === $route.name }"
        @click="changePage(item.name)">
        <label>{{ item.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import Curve from '@/components/AnimationCollection/CurveFlash.vue'
import SliderButton from '@/components/AnimationCollection/SliderButton.vue'
import MapPart from '@/components/Map/MapPart'
import SideBar from '@/components/SideBar.vue'
import { COMM_LIST } from '@/assets/StaticData'
import { fetchAlarmTotal } from '@/api/alarm'
import Jieqi from '@/utils/jieqi'

const PATH = [
  {
    label: '社区\n绩效',
    name: 'CommunityView'
  },
  {
    label: '预警\n信息',
    name: 'AlarmView'
  },
  {
    label: '垃圾\n分类',
    name: 'RubbishView'
  },
  {
    label: '应急\n调度',
    name: 'EpidemicView'
  }
]

export default {
  name: 'CommonLayout',
  components: {
    Curve,
    SliderButton,
    SideBar,
    MapPart
  },
  data() {
    return {
      current: MapPart,
      commCode: this.$store.state.currentCommCode,
      COMM_LIST,
      slideIn: true,
      PATH,
      leftExpend: false,
      rightExpended: false,
      bottomExpended: true,
      time: {
        date: '2018-01-01',
        time: '00:00:00',
        week: '周日',
        calen: '腊月初一',
        jieqi: '春节'
      },
      timer: null
    }
  },
  watch: {
    '$store.state.currentCommCode': {
      handler() {
        this.$refs.leftOne ? this.$refs.leftOne.getData() : null
        this.$refs.leftTwo ? this.$refs.leftTwo.getData() : null
        this.$refs.leftThree ? this.$refs.leftThree.getData() : null
        this.$refs.leftSmallOne ? this.$refs.leftSmallOne.getData() : null
        this.$refs.leftSmallTwo ? this.$refs.leftSmallTwo.getData() : null
        this.$refs.rightSmallOne && this.$refs.rightSmallOne.getDat
          ? this.$refs.rightSmallOne.getData()
          : null
        this.$refs.rightSmallOne && this.$refs.rightSmallOne.getAvg
          ? this.$refs.rightSmallOne.getAvg()
          : null
        this.$refs.rightSmallTwo && this.$refs.rightSmallTwo.getData
          ? this.$refs.rightSmallTwo.getData()
          : null
        this.$refs.rightSmallThree && this.$refs.rightSmallThree.getData
          ? this.$refs.rightSmallThree.getData()
          : null
        this.$refs.rightOne && this.$refs.rightOne.getData ? this.$refs.rightOne.getData() : null
        this.$refs.rightTwo && this.$refs.rightTwo.getData ? this.$refs.rightTwo.getData() : null
        this.$refs.rightThree && this.$refs.rightThree.getData
          ? this.$refs.rightThree.getData()
          : null
        if (this.$route.name === 'AlarmView') {
          this.getData()
        }
      }
    },
    '$route.name': {
      handler() {
        if (this.$route.name === 'AlarmView') {
          this.getData()
        }
      }
    }
  },
  created() {
    if (this.$route.name === 'AlarmView') {
      this.getData()
    }
  },
  mounted() {
    setTimeout(() => {
      this.slideIn = true
      setTimeout(() => {
        this.leftExpend = true
        this.rightExpended = true
      }, 500)
    }, 250)
    this.timer = setInterval(() => {
      this.time = Jieqi.showTime()
    }, 1000)
  },
  methods: {
    async getData() {
      const { data } = await fetchAlarmTotal()
      this.$store.commit('updateAlarmStat', Object.assign({}, data))
    },
    updateComm(name) {
      this.$store.commit('updateCommName', name)
    },
    changePage(name) {
      this.slideIn = false
      this.leftExpend = false
      this.rightExpended = false
      this.$router.push({ name })
      setTimeout(() => {
        this.slideIn = true
        setTimeout(() => {
          this.leftExpend = true
          this.rightExpended = true
        }, 500)
      }, 500)
    },
    updateLeft() {
      this.leftExpend = !this.leftExpend
    },
    updateRight() {
      this.rightExpended = !this.rightExpended
    },
    updateBottom() {
      this.bottomExpended = !this.bottomExpended
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/style/variables.less');
@pad: 24px;
.moveLeft-enter-active {
  animation: ml 1s;
}
.moveLeft-leave-active {
  animation: ml 1s reverse;
  filter: brightness(0.1);
}
@keyframes ml {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translate(0);
    opacity: 1;
  }
}
.moveRight-enter-active {
  animation: mr 1s;
}
.moveRight-leave-active {
  animation: mr 1s reverse;
  filter: brightness(0.1);
}
@keyframes mr {
  from {
    transform: translateX(100%) rotateY(-180deg);
    opacity: 0;
  }
  to {
    transform: translateX(0) rotateY(-180deg);
    opacity: 1;
  }
}
.layout {
  display: grid;
  grid-template-columns: auto 1fr auto;
  background: #070707;
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  z-index: 1;
  .big-frame-inner {
    position: relative;
  }
  &.CommunityView {
    .big-frame-inner {
      &.left {
        grid-template-rows: 34% 28% 38%;
      }
    }
    .small-frame-inner {
      &.left {
        grid-template-rows: 75% 25%;
      }
    }
  }
  &.AlarmView {
    .big-frame-inner {
      &.right {
        .number-label {
          margin: 0 6% 0 2.5%;
        }
      }
    }
    .small-frame-inner {
      &.left {
        grid-template-rows: 24% 36% 40%;
      }
    }
  }
  &.EpidemicView {
    .big-frame-inner {
      &.left {
        grid-template-rows: 26% 74%;
      }
      &.right {
        grid-template-rows: 60% 40%;
      }
    }
    .small-frame-inner {
      &.right {
        grid-template-rows: repeat(3, 1fr);
      }
    }
  }
  &.RubbishView {
    .big-frame-inner {
      &.left {
        grid-template-rows: 36% 64%;
      }
      &.right {
        grid-template-rows: repeat(2, 1fr);
      }
    }
    .small-frame-inner {
      &.left {
        grid-template-rows: 1fr;
      }
    }
  }
  .slider {
    position: relative;
    display: flex;
    left: -100%;
    transition: all 0.3s linear;
    overflow: hidden;
    .bar {
      bottom: 20%;
      left: -15%;
    }
    &.slideIn {
      left: 0;
    }
    &.right {
      grid-area: 2 / 3 / span 2 / span 3;
      transform: rotateY(-180deg);
      left: unset;
      right: -100%;
      .bar {
        bottom: 20%;
        left: unset;
        right: -15%;
        transform: rotateY(-180deg);
      }
      &.slideIn {
        right: 0;
      }
    }
    &-big {
      height: calc(100vh - 108px);
      aspect-ratio: 1477 / 962;
      background-image: url('@/assets/svg/layout/slide-big.svg');
    }
    &-small {
      opacity: 0;
      z-index: -1;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      position: absolute;
      height: calc((100vh - 108px) * 0.85);
      aspect-ratio: 850 / 823;
      background-image: url('@/assets/svg/layout/slide-small.svg');
      transition: all 0.3s ease-in-out;
      &.expended {
        z-index: unset;
        opacity: 1;
        left: 50%;
      }
    }
  }
  &-bottom {
    gap: 80px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    place-items: center;
    justify-content: center;
    aspect-ratio: 2140 / 166;
    background-image: url('@/assets/svg/layout/bottom.svg');
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -150px;
    transition: all 0.3s ease-in-out;
    color: #fff;
    .btn-item {
      aspect-ratio: 161 / 161;
      background-image: url('@/assets/svg/layout/button.svg');
      width: 112px;
      position: relative;
      display: grid;
      place-items: center;
      position: relative;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      opacity: 0.75;
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('@/assets/svg/layout/button-rotate.svg');
        background-repeat: no-repeat;
        animation: rotate 2.5s linear infinite;
        top: 0;
        left: 0;
      }
      label {
        font-size: 12px;
        white-space: pre;
      }
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }
      &.active,
      &:hover {
        opacity: 1;
        background-image: url('@/assets/svg/layout/button-active.svg');
        transform: scale(1.1);
        &::before {
          background-image: url('@/assets/svg/layout/button-rotate-active.svg');
          animation: rotate 1s linear infinite;
        }
      }
      &.active {
        &::before {
          animation: rotate 2.5s linear infinite;
        }
      }
    }
    &.expended {
      bottom: 0;
      :deep(.slider-btn) {
        .arrows {
          transform: scaleY(0.4) rotate(0);
        }
      }
    }
    :deep(.slider-btn) {
      top: 0;
      transform: translate(-50%, -80%) scaleX(2.5) rotateZ(270deg);
      height: 100px;
      width: 50px;
      left: 50%;
      .arrows {
        transform: scaleY(0.4) rotate(180deg);
      }
    }
  }
  &-header {
    grid-area: auto / span 5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 108px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      transform: translateX(-50%);
      background-image: url('@/assets/svg/layout/header-line.svg');
      background-size: 100%;
      left: 50%;
      bottom: 0;
      aspect-ratio: 2978 / 104;
      width: 80%;
    }
    &::after {
      content: '';
      @animateLazer();
      position: absolute;
      bottom: 5px;
      width: 38%;
      height: 8px;
    }
    :deep(&__drop) {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, calc(100% + 3px));
      border: 2px solid #229de3;
      border-radius: 8px;
      @trim: {
        background-image: url('~@/assets/svg/trim.svg');
        background-size: 100%;
        aspect-ratio: 29 / 36;
        width: 24px;
        position: absolute;
      };
      &::before {
        content: '';
        @trim();
        left: 0;
        transform: translate(calc(-100% + 8px), -12px);
      }
      &::after {
        content: '';
        @trim();
        right: 0;
        transform: translate(calc(100% - 8px), -12px) rotateY(180deg);
      }
      .el-input__prefix {
        display: flex;
        align-items: center;
      }
      svg {
        width: 18px;
        height: 18px;
      }
      .el-input {
        position: relative;
        @size: 5px;
        @rect: {
          width: @size;
          height: @size;
          background: #04f6ff;
          position: absolute;
          top: calc(50% - @size / 2);
          animation: circling 3s linear infinite;
        };
        @keyframes circling {
          100% {
            transform: rotate(360deg);
          }
        }
        &::before {
          content: '';
          @rect();
          left: -(@size / 2 + 1px);
        }
        &::after {
          content: '';
          @rect();
          right: -(@size / 2);
        }
      }
      input {
        height: 32px !important;
        line-height: 32px !important;
        border-radius: 8px;
        border: none;
        background-color: rgba(22, 26, 32, 0.54);
        box-shadow: 0 1px 11px 0 rgba(142, 211, 255, 0.48) inset;
        text-align: center;
      }
      .el-input__icon {
        line-height: 32px;
      }
    }
    .time-wrap {
      position: fixed;
      top: 50px;
      font-size: 20px;
      font-weight: 400;
      color: #cae2fb;
      line-height: 25px;
      letter-spacing: 2px;
      text-shadow: 0 0 10px #cae2fb;
      &.left {
        left: 300px;
      }
      &.right {
        right: 300px;
      }
      .date-s {
        margin-right: 30px;
      }
      .time-s {
        margin-left: 30px;
      }
    }
    .title-wrap {
      position: relative;
      padding: 0 @pad;
      display: flex;
      place-items: center;
      height: 100%;
      background-image: url('@/assets/svg/layout/title-bg.svg');
      .curve-wrap {
        left: 0;
        position: absolute;
        height: 72px;
        width: 102px;
        transform: translate(calc(-100% - 36px - @pad), 6px);
        &.reverse {
          left: unset;
          right: 0;
          transform: translateX(100% + 36px + @pad);
        }
      }
    }
    .title {
      font-size: 42px;
      background-image: linear-gradient(to bottom, #eeeeee, #9fdcff);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: 3s toTop linear infinite;
      background-position-y: 0;
      user-select: none;
    }
    @keyframes toTop {
      50% {
        background-position-y: 25px;
      }
      100% {
        background-position-y: 60px;
      }
    }
  }
  &-map {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}
</style>
