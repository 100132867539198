<template>
  <div class="bar full-bg">
    <div
      v-for="idx in 16"
      :key="`tsbar-${idx}`"
      class="bar-item"
      :class="{ even: idx % 2 === 0 }"
      :style="{ animationDelay: `${idx * random(0, 1)}s` }"
    />
  </div>
</template>

<script>
import { random } from 'lodash'
export default {
  methods: {
    random
  }
}
</script>

<style lang="less" scoped>
.bar {
  display: grid;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 350px;
  background-image: url('~@/assets/svg/bar.svg');
  &::before {
    position: absolute;
    left: 0;
    top: -12px;
    content: '';
    width: 5px;
    height: calc(100% + 24px);
    background-image: radial-gradient(rgba(49, 131, 255, 1), rgba(13, 23, 25, 0));
    transform: translateX(calc(-100% + 1px));
  }
  &-item {
    background-color: #277cfc;
    height: 4px;
    animation: stretch 1.5s ease-in-out infinite;
    &.even {
      height: 2px;
      animation: stretchSilm 2s ease-in-out infinite;
    }
    &:nth-child(n + 9):nth-child(-n + 12) {
      background-color: #f7b500;
    }
  }
  @keyframes stretch {
    0% {
      width: 100%;
    }
    50% {
      width: 130%;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes stretchSilm {
    0% {
      width: 80%;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 80%;
    }
  }
}
</style>
