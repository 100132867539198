import { render, staticRenderFns } from "./SliderButton.vue?vue&type=template&id=417f1361&scoped=true"
import script from "./SliderButton.vue?vue&type=script&lang=js"
export * from "./SliderButton.vue?vue&type=script&lang=js"
import style0 from "./SliderButton.vue?vue&type=style&index=0&id=417f1361&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417f1361",
  null
  
)

export default component.exports