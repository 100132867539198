<template>
  <div class="curve-wrap" :class="{ reverse: reverse }">
    <div
      v-for="idx in num"
      :key="`curve${idx}`"
      class="item full-bg"
      :style="{ left: `${idx * 20}px`, animationDelay: `${idx * ratio}s` }"
    />
  </div>
</template>

<script>
export default {
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    num: {
      type: Number,
      default: 5
    }
  },
  computed: {
    ratio() {
      return this.reverse ? 0.1 : -0.1
    }
  }
}
</script>

<style lang="less">
.curve-wrap {
  position: relative;
  display: flex;
  &.reverse {
    .item {
      transform: rotateY(-180deg);
    }
  }
  .item {
    height: 60px;
    width: 20px;
    animation: flash 2s linear infinite;
    background-image: url('@/assets/svg/layout/curve.svg');
  }
  @keyframes flash {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
