import Vue from 'vue'

export const drag = Vue.directive('autowidth', {
  bind(el, binding, vnode) {
    let getComputedStyles = dom => {
      return window.getComputedStyle ? getComputedStyle(dom, null) : dom.currentStyle
    }
    let doUnit = val => {
      return typeof val === 'number' ? val + 'px' : val
    }
    let spanClass = `auto-width-${vnode.context._uid}`,
      spanElement = document.createElement('span')

    el.style.position = 'relative'
    spanElement.style.position = 'absolute'
    spanElement.style.height = 0
    spanElement.style.zIndex = -9999
    // spanElement.style.right = '-9999px'
    spanElement.style.top = 0
    spanElement.style.visibility = 'hidden'
    spanElement.style.opacity = 0
    spanElement.style.display = 'inline-block'
    spanElement.style['word-break'] = 'keep-all'
    spanElement.style['white-space'] = 'nowrap'

    spanElement.innerText = binding.value
    spanElement.className = spanClass
    vnode.context.spanClass = spanClass
    vnode.context.getComputedStyles = getComputedStyles
    vnode.context.doUnit = doUnit

    el.appendChild(spanElement)

    let styleKeys = [
      'paddingLeft',
      'paddingRight',
      'borderLeft',
      'borderRight',
      'outlineWidth',
      'fontWeight',
      'fontSize',
      'boxSizing'
    ]

    vnode.context.$nextTick(() => {
      let inputDom = el.querySelector('input'),
        styles = getComputedStyles(inputDom)

      styleKeys.forEach(s => {
        spanElement.style[s] = styles[s]
      })

      inputDom.style.width = '100%'
      el.style.width = getComputedStyles(spanElement).width
      if (binding?.arg?.min) {
        el.style.minWidth = doUnit(binding.arg.min)
      }
      if (binding?.arg?.max) {
        el.style.maxWidth = doUnit(binding.arg.max)
      }
    })
  },
  update(el, binding, vnode) {
    if (binding.value !== binding.oldValue) {
      let spanElement = el.querySelector('.' + vnode.context.spanClass)
      spanElement.innerText = binding.value
      vnode.context.$nextTick(() => {
        el.style.width = vnode.context.getComputedStyles(spanElement).width
      })
    }
  }
})
