<template>
  <el-container v-loading="warnLoading" element-loading-background="rgba(0,0,0,0.6)">
    <div class="warning-detail-area">
      <p class="detail-p">位置：{{ alarmData.location }}</p>
      <div class="img-area">
        <img
          :src="
            alarmData.image_path && alarmData.image_path !== ''
              ? alarmData.image_path
              : alarmData.face
          "
          alt=""
          v-if="picShow" />
        <video
          v-else
          loop
          muted
          autoplay="autoplay"
          class="videoBack"
          controls
          width="400"
          height="230"
          style="object-fit: cover; float: left"
          ref="videoPlay"
          :custom-cache="false">
          <source :src="source" type="video/mp4" />
        </video>
        <div class="description" :class="alarmData.video_path ? 'small' : ''">
          <div class="description-title">报警原因</div>
          <div class="description-detail" :title="alarmData.alarm_condition">
            {{ alarmData.alarm_condition }}
          </div>
        </div>
        <div class="sure-btn" v-if="alarmData.video_path" @click="changePicAndVideo()">
          {{ picShow ? '视频' : '图片' }}确认
        </div>
      </div>
      <div class="data-area">
        <div class="data-area-box" v-for="(item, index) in detailArr" :key="`detail${index}`">
          <div class="data-area-box-title">
            {{ item.title }}
          </div>
          <div
            class="data-area-box-value"
            :class="
              index < 3
                ? 'blue'
                : index >= 3 && index < 5
                ? 'red'
                : index >= 5 && index < 8
                ? 'small'
                : ''
            ">
            {{ alarmData[item.value] }}
          </div>
        </div>
        <div class="data-area-box">
          <div class="data-area-box-title">归档时间</div>
          <div class="data-area-box-value">
            {{ getInervalHour(alarmData.created_at, alarmData.closed_at, alarmData.status) }}
          </div>
        </div>
        <div class="data-area-box">
          <div class="data-area-box-title">催办记录</div>
          <div class="data-area-box-value blue cursor" @click="detailFun">查看</div>
        </div>
      </div>
      <div class="data-area-second">
        <p>处理人：{{ alarmData.agent_name }}</p>
        <p>手机号：{{ alarmData.agent_mobile }}</p>
      </div>
      <div class="data-area-third">
        <div class="data-area-box">
          <div class="data-area-box-title">该位置历史预警数</div>
          <div class="data-area-box-title-small">近（30）天</div>
          <div class="data-area-box-value red">
            <span class="num">{{ alarmData.query_same_loc }}</span
            >件
          </div>
        </div>
        <div class="data-area-box">
          <div class="data-area-box-title">该位置历史预警数</div>
          <div class="data-area-box-title-small">近（30）天</div>
          <div class="data-area-box-value yellow">
            <span class="num">{{ alarmData.query_same_info }}</span
            >件
          </div>
        </div>
      </div>
      <!-- 催办弹窗 -->
      <el-dialog
        title="处理记录"
        :visible.sync="lifeDialogVisible"
        width="600px"
        style="left: 1200px"
        :modal="false"
        custom-class="common-dialog-bg"
        :append-to-body="true">
        <el-container
          class="common-dialog-padding"
          v-loading="loading"
          element-loading-background="transparent">
          <el-timeline class="time-line-class">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              color="rgb(61, 203, 241)"
              :timestamp="activity.time">
              {{ activity.name }}
            </el-timeline-item>
          </el-timeline>
        </el-container>
      </el-dialog>
    </div>
  </el-container>
</template>
<script>
const ALARMDATA = {
  alarm_id: null,
  comm_code: null,
  title: null,
  comm_name: null,
  location: null,
  alarm_level: null,
  status: null,
  agent_name: null,
  created_at: null,
  handle_time: null,
  updated_at: null,
  query_same_loc: 0,
  query_same_info: 0,
  alarm_condition: null,
  agent_mobile: null,
  resd_name: null,
  resd_mobile: null,
  printed_id: null,
  image_path: null,
  video_path: null,
  vpn_attachment: null,
  vpn_video: null,
  vpn_source: null,
  handle_at: null,
  closed_at: null,
  apt_desc: null,
  face: null
}

import { alarmDetails, alarmProcess } from '@/api/comm'
import alarmVideo from '@/assets/video.mp4'
import carVideo from '@/assets/car.mp4'
import doorVideo from '@/assets/door.mp4'
import videojs from 'video.js'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'
import DOOR_OPEN from '@/assets/image/door_open.jpg'

export default {
  name: 'warningDetail',
  props: {
    detailData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      detailArr: [
        { title: '预警人', value: 'resd_name' },
        { title: '预警卡号', value: 'printed_id' },
        { title: '预警手机号', value: 'resd_mobile' },
        { title: '预警级别', value: 'alarm_level' },
        { title: '预警状态', value: 'status' },
        { title: '预警时间', value: 'created_at' },
        { title: '处置时间', value: 'handle_at' },
        { title: '结案时间', value: 'closed_at' }
      ],
      alarmData: ALARMDATA,
      picShow: true,
      videoUrl: '',
      myRTSPPlayer: null,
      lifeDialogVisible: false,
      activities: [],
      loading: true,
      warnLoading: true,
      source: null
    }
  },
  watch: {
    detailData() {
      this.alarmData = ALARMDATA
      this.picShow = true
      this.warnLoading = true
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const { data } = await alarmDetails({ id: this.detailData.id })
      if (data) {
        this.alarmData = data
        if (this.alarmData.title === '门未关') {
          this.alarmData.image_path = DOOR_OPEN
        }
        this.warnLoading = false
      }
    },
    getInervalHour(date, closeDate, status) {
      let h = 0
      let m = 0
      // 先算超时的小时,再算超时的分钟,超时的分钟减去小时
      var startDate = new Date(date)
      var endDate
      if (status === '关闭') {
        endDate = new Date(closeDate)
      } else {
        endDate = new Date()
      }
      var ms = endDate.getTime() - startDate.getTime()
      if (ms < 0) return '0h0m'
      h = Math.floor(ms / 1000 / 60 / 60)
      let m_floor = h * 60
      m = Math.floor(ms / 1000 / 60) - m_floor
      return `${h || 0}h${m || 0}m` || ''
    },
    changePicAndVideo() {
      this.picShow = !this.picShow
      this.source =
        this.alarmData.title == '高空抛物'
          ? alarmVideo
          : this.alarmData.title == '门未关'
          ? doorVideo
          : carVideo
    },
    //播放
    playVideo() {
      var myPlayer = videojs('my-video')
      myPlayer.src([
        {
          type: 'application/x-mpegURL',
          src: this.alarmData.video_path
        }
      ])
      myPlayer.play()
      this.myRTSPPlayer = myPlayer
    },
    //处理记录
    async detailFun() {
      this.loading = true
      this.lifeDialogVisible = true
      let { data } = await alarmProcess({ id: this.detailData.id })
      if (data) {
        this.activities = data
        this.loading = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.warning-detail-area {
  color: #fff;
  font-size: 14px;
  .detail-p {
    line-height: 30px;
  }
  .img-area {
    width: 100%;
    height: 230px;
    img {
      width: 400px;
      height: 100%;
      float: left;
      background: rgba(35, 133, 190, 0.5);
    }
    .alarm-video {
      width: 400px;
      height: 100%;
      float: left;
    }
    .description {
      width: calc(100% - 410px);
      height: calc(100% - 2px);
      border-radius: 5px;
      border: 1px solid rgba(67, 184, 251, 1);
      background: rgba(35, 133, 190, 0.5);
      float: right;
      box-sizing: border-box;
      padding: 10px;
      &.small {
        height: calc(100% - 52px);
      }
      &-title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: rgba(35, 133, 190, 0.5);
      }
      &-detail {
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .sure-btn {
      width: calc(100% - 410px);
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
      border: 1px solid rgba(67, 184, 251, 1);
      background: rgba(35, 133, 190, 0.5);
      margin-top: 10px;
      text-align: center;
      float: right;
      cursor: pointer;
    }
  }
  .data-area {
    width: 100%;
    height: 160px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background: rgba(35, 133, 190, 0.5);
    margin-top: 10px;
    .data-area-box {
      height: 80px;
      border-bottom: 1px solid rgba(67, 184, 251, 1);
      border-right: 1px solid rgba(67, 184, 251, 1);
      text-align: center;
      box-sizing: border-box;
      padding: 10px;
      &-title {
        line-height: 30px;
      }
      &-value {
        line-height: 30px;
        &.blue {
          color: rgb(61, 203, 241);
        }
        &.red {
          color: rgb(255, 74, 74);
        }
        &.small {
          line-height: 15px;
          text-indent: 8px;
        }
        &.cursor {
          cursor: pointer;
        }
      }
      &:nth-child(5),
      &:nth-child(10) {
        border-right: unset;
      }
    }
  }
  .data-area-second {
    width: 100%;
    height: 80px;
    border-radius: 5px;
    border: 1px solid rgba(67, 184, 251, 1);
    background: rgba(35, 133, 190, 0.5);
    margin-top: 10px;
    p {
      width: 50%;
      float: left;
      line-height: 80px;
      text-indent: 20px;
    }
  }
  .data-area-third {
    width: 100%;
    height: 120px;
    border-radius: 5px;
    background: rgba(35, 133, 190, 0.5);
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    box-sizing: border-box;
    padding: 20px 0;
    .data-area-box {
      text-align: center;
      &-title {
        line-height: 25px;
      }
      &-title-small {
        line-height: 20px;
        font-size: 12px;
      }
      &-value {
        line-height: 50px;
        &.red {
          color: #c5352b;
        }
        &.yellow {
          color: rgb(246, 169, 88);
        }
        .num {
          font-size: 30px;
        }
      }
    }
  }
}
.common-dialog-padding {
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  padding: 20px;
  .time-line-class /deep/.el-timeline-item__content {
    color: #fff;
  }
  .time-line-class /deep/.el-timeline-item__timestamp {
    color: rgb(61, 203, 241);
  }
}
</style>
