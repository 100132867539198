import Vue from 'vue'
import Vuex from 'vuex'
import { find, concat } from 'lodash'
import { fetchAlarmInfo, fetchAllCommunities } from '@/api/comm'

Vue.use(Vuex)

const DEFAULT_CODE = 'T0001'

export default new Vuex.Store({
  state: {
    currentYear: undefined,
    currentPeroid: undefined,
    commList: [{ commName: '镇级数据', commCode: DEFAULT_CODE }],
    alarmInfoList: [{ name: '全部', code: 0 }],
    currentCommCode: DEFAULT_CODE,
    totalAlarm: 0,
    totalUrge: 0,
    unassigned: {
      notAssignedOne: 0,
      notAssignedTwo: 0,
      notAssignedThree: 0
    },
    unsolved: {
      weiJieAnOne: 0,
      weiJieAnThree: 0,
      weiJieAnTwo: 0
    },
    responseTrend: [],
    numTrend: [],
    recentYear: [],
    mapSiteData: '{}',
    videoMark: '',
    changeVideoMark: ''
  },
  getters: {
    mapSiteData: state => state.mapSiteData,
    isDefaultComm: state => {
      return state.currentCommCode === DEFAULT_CODE
    },
    currentCommName: state => {
      return find(state.commList, ({ commCode }) => commCode === state.currentCommCode).commName
    }
  },
  mutations: {
    updateYear(state, year) {
      state.currentYear = year
    },
    updatePeriod(state, period) {
      state.currentPeroid = period
    },
    updateAlarmStat(
      state,
      {
        total,
        urgeTotal,
        notAssignedOne,
        notAssignedTwo,
        notAssignedThree,
        weiJieAnOne,
        weiJieAnThree,
        weiJieAnTwo
      }
    ) {
      state.totalAlarm = total
      state.totalUrge = urgeTotal
      state.unassigned = Object.assign({}, { notAssignedOne, notAssignedTwo, notAssignedThree })
      state.unsolved = Object.assign({}, { weiJieAnOne, weiJieAnThree, weiJieAnTwo })
    },
    updateCommList(state, arr) {
      state.commList = concat(state.commList, arr)
    },
    updateAlarmInfoList(state, arr) {
      state.alarmInfoList = concat(state.alarmInfoList, arr)
    },
    updateCommName(state, name) {
      state.currentCommCode = name
    },
    updateMapSiteData(state, mapSiteData) {
      state.mapSiteData = mapSiteData
    }
  },
  actions: {
    async getBasicData({ commit }) {
      const [{ data: infoRes }, { data: commRes }] = await Promise.all([
        fetchAlarmInfo(),
        fetchAllCommunities()
      ])
      commRes.push({ commName: '四季悦园 (接入中)' })
      commit('updateCommList', commRes)
      commit('updateAlarmInfoList', infoRes)
    },
    mapSiteData(context, mapSiteData) {
      context.commit('updateMapSiteData', mapSiteData)
    }
  }
})
