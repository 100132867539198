<template>
  <div class="slider-btn" @click="clickFunc" :class="{ expended: expended }">
    <div
      v-for="idx in 3"
      :key="`arrow${idx}`"
      class="arrows full-bg"
      :style="{ animationDelay: `${idx * -0.4}s` }"
    />
  </div>
</template>

<script>
export default {
  props: {
    outerExpended: {
      type: Boolean,
      defualt: false
    }
  },
  data() {
    return {
      expended: this.outerExpended
    }
  },
  watch: {
    outerExpended(val) {
      this.expended = val
    }
  },
  methods: {
    clickFunc() {
      this.expended = !this.expended
      this.$emit('update')
    }
  }
}
</script>

<style lang="less" scoped>
.slider-btn {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 80px;
  width: 40px;
  left: 50%;
  top: 50%;
  transform: translate(0, -50%) scaleY(2.5);
  position: absolute;
  border-radius: 0 100px 100px 0;
  background: linear-gradient(to right, rgba(4, 21, 28, 1), rgba(67, 154, 227, 0.65));
  &.expended {
    left: 95%;
    .arrows {
      transform: scaleY(0.4) rotate(0);
    }
  }
  .arrows {
    aspect-ratio: 7 / 20;
    width: 7px;
    background-image: url('@/assets/svg/layout/arrow.svg');
    transform: scaleY(0.4) rotate(180deg);
    animation: flash 2s linear infinite;
  }
  @keyframes flash {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
