<template>
  <div class="map-area">
    <div class="map-background" style="width: 100vw; height: 100vh">
      <div class="sky-space">
        <div class="sky-stars"></div>
        <div class="sky-stars"></div>
        <div class="sky-stars"></div>
        <div class="sky-stars"></div>
      </div>
      <div class="sky-space-bg">
        <div class="sky-bg-stars"></div>
        <div class="sky-bg-stars"></div>
        <div class="sky-bg-stars"></div>
        <div class="sky-bg-stars"></div>
      </div>
    </div>
    <div id="mapBox">
      <iframe
        scrolling="no"
        class="map-bg"
        width="100%"
        height="100%"
        :allowtransparency="true"
        frameborder="no"
        id="gisShow"
        name="gisShow">
      </iframe>
      <!-- 地图图例 -->
      <div class="menu">
        <div
          v-for="item in MENU"
          :key="`comm-${item.class}`"
          class="menu-btn"
          :class="mapClickActive.includes(item.class) ? 'active' : ''"
          @click="mapClick(item.class)">
          <el-tooltip class="item" effect="dark" :content="item.name" placement="left">
            <svg-icon :icon-class="`menu-${item.class}`"
          /></el-tooltip>
        </div>
      </div>
      <!-- 设备位置搜索框 -->
      <div class="equipment-search" v-show="mapClickActive.includes('camera')">
        <el-select
          v-model="equipmentSearch"
          placeholder="一键搜索：设备位置"
          size="small"
          suffix-icon="el-icon-search"
          filterable
          no-match-text="未查询到此设备"
          :default-first-option="true"
          @change="anchorPoint">
          <el-option
            v-for="item in allEquipmentData"
            :key="item.ipAddr"
            :label="item.locDescription"
            :value="item.ipAddr">
          </el-option>
        </el-select>
      </div>
      <!-- 报警类型图例 -->
      <div class="alarm-type-menu">
        <div
          v-for="(item, index) in alarmType"
          :key="`alarm-${item.id}`"
          class="alarm-type-menu-btn"
          :class="alarmActive == index ? 'active' : ''"
          @click="mapAlarmPoint(item, index)">
          <span class="btn-idx">{{ index + 1 }}</span>
          <span class="btn-words">{{ item.type }}</span>
        </div>
      </div>
      <!-- 报警弹窗 -->
      <el-dialog
        :title="alarmDetail.type"
        :visible.sync="warningDialogVisible"
        width="600px"
        center
        :modal="false"
        custom-class="common-dialog-bg"
        :append-to-body="true"
        element-loading-background="transparent">
        <WarningDetail :detailData="alarmDetail" />
      </el-dialog>
      <!-- 视频弹窗 -->
      <el-dialog
        :title="videoTitle"
        :visible.sync="videoDialogVisible"
        width="600px"
        center
        :modal="false"
        custom-class="common-dialog-bg"
        :append-to-body="true"
        @close="stopVideo"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div class="video-dialog">
          <el-popover
            placement="start-bottom"
            width="180"
            trigger="manual"
            v-model="videoPartVisible">
            <p class="video-dialog-title">固定此视频到:</p>
            <el-radio-group v-model="selectVideoPart" size="mini">
              <el-radio label="1">1号探头窗口</el-radio>
              <el-radio label="2">2号探头窗口</el-radio>
              <el-radio label="3">3号探头窗口</el-radio>
            </el-radio-group>
            <div class="sure" @click="setVedioMarkFun">确认</div>
            <div class="sure mr-10" @click="videoPartVisible = !videoPartVisible">取消</div>
            <div
              slot="reference"
              class="subscribe"
              title="定位视频"
              @click="videoPartVisible = !videoPartVisible">
              <img src="~@/assets/image/ding.png" alt="" />
            </div>
          </el-popover>
          <video
            id="base-video"
            class="video-js vjs-default-skin"
            controls
            preload="auto"
            muted
            autoplay="autoplay"
            style="width: 100%; height: 100%; object-fit: fill">
            <source :src="videoBaseUrl" type="application/x-mpegURL" />
          </video>
        </div>
      </el-dialog>

      <!-- 选择设备弹窗 -->
      <el-dialog
        :title="'设备列表'"
        :visible.sync="equipmentDialogVisible"
        width="300px"
        center
        :modal="false"
        custom-class="common-dialog-bg"
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div class="equipment-dialog">
          <div class="ipAddr" v-for="item in ipAddrArrs" :key="item" @click="showVideo(item)">
            {{ item.ipAddr }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { filter, includes } from 'lodash'
import WarningDetail from '@/components/Map/Components/WarningDetail'
import { fetchDevices, fetchAlarmType, turnXY, fetchBuildings, setVedioMark } from '@/api/comm'
import { mapGetters } from 'vuex'
import videoIcon from '@/assets/image/videoIcon.png'
import warnIcon from '@/assets/image/warnIcon.png'
import houseIcon from '@/assets/image/houseIcon.png'
import videojs from 'video.js'
import { COORD, COMMUNITY_AREA } from './data.js'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'
var bridge = null
const MENU = [
  {
    name: '卫星地图',
    class: 'zoom'
  },
  {
    name: '楼栋号',
    class: 'building'
  },
  {
    name: '报警',
    class: 'water'
  },
  {
    name: '摄像头',
    class: 'camera'
  },
  {
    name: '清空图层',
    class: 'close'
  }
]
const ALARM_TYPE = [
  '轨迹异常',
  '异常出入',
  '非机动车进楼道',
  '门未关',
  '高空抛物',
  '群租可能',
  '独居老人',
  '七天未出入'
]
const BASEVIDEO = process.env.VUE_APP_BASE_VIDEO
export default {
  name: 'MapPart',
  components: { WarningDetail },
  data() {
    return {
      MENU,
      cdmap: null,
      commCode: null,
      warningDialogVisible: false, //报警弹窗
      mapClickActive: [],
      alarmType: [],
      argData: null,
      alarmDetail: {},
      alarmActive: null,
      videoBaseUrl: null,
      videoBaseId: null,
      myRTSPPlayer: null,
      videoDialogVisible: false,
      videoTitle: '',
      alreadyStop: true,
      ip3: '',
      equipmentDialogVisible: false,
      ipAddrArrs: [],
      pointDetailAll: null,
      allEquipmentData: [], //全部设备数据
      equipmentSearch: '',
      selectVideoPart: null,
      selectVideoPartIp: '',
      videoPartVisible: false
    }
  },
  computed: {
    getCommCode() {
      return this.$store.state.currentCommCode
    },
    ...mapGetters(['mapSiteData'])
  },
  watch: {
    getCommCode(val) {
      this.commCode = val
      if (this.mapClickActive.includes('camera')) {
        this.fetchDevicesFun()
      }
      if (this.mapClickActive.includes('water')) {
        this.fetchAlarmTypeFun()
      }
      if (this.mapClickActive.includes('building')) {
        this.getBuildingsFun()
      }
    },
    mapSiteData(val) {
      const data = JSON.parse(val)
      this.clearSingleFun('video_layer')
      this.clearSingleFun('more_video_layer')
      const setMapSiteData = data.pointDetail
      const setCommunityArea = data.community
      const fontName = 'video_layer'
      const rendererData = {
        label: '视频设备',
        url: videoIcon,
        width: '17.5px',
        height: '28px',
        fields: ''
      }
      if (data.moreSite) {
        this.pointDetailAll = data.pointDetail
        this.setEquipmentList(data.pointDetail, 'more_video_layer', rendererData)
        this.setAreaFun(setCommunityArea)
      } else {
        this.setPointFun(setMapSiteData, fontName, rendererData)
      }
    }
  },
  activated() {
    if (!this.cdmap) {
      this.initGisMap()
    }
  },
  methods: {
    //初始化
    async initGisMap() {
      try {
        var _that = this
        bridge = await new window.CityGis.Bridge({
          id: 'gisShow',
          url: 'http://32.1.8.189/citygis/areamap/WidgetPages/WidgetGIS.html?code=1214&themeid=Gis&devicetype=lg&mapType=3d',
          onReady: function () {
            //定位闪烁
            //地图加载完后，初始化自动移动到某一个位置
            bridge.Invoke({
              ActionName: 'goToPosition',
              Parameters: {
                positon: {
                  x: '-10667.449374745573',
                  y: '-22554.338754226323'
                },
                hasImg: false,
                hasvideo: false,
                zoom: 5,
                isRotation360: false
              }
            })
          }
        })
        _that.cdmap = bridge
        //鼠标操作地图回发消息处理
        bridge.addEventListener(arg => {
          switch (arg.action) {
            case 'mapclick':
              console.log('点击触发:', arg.data)
              if (arg.data.alarm_layer) {
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.alarmDialogFun()
              } else if (arg.data.video_layer) {
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.videoDialogFun()
              } else if (arg.data.more_video_layer) {
                //视频同一坐标存在多个设备
                let flagName = Object.keys(arg.data)[0]
                _that.argData = arg.data[flagName][0]
                _that.openEquipmentList()
              }

              //地图点选消息
              break
            case 'getPoint':
              console.log('点击触发:', arg.data)
              _that.nowPoint = [arg.data['坐标X'], arg.data['坐标Y']]
              _that.breathPoint(arg.data['坐标X'], arg.data['坐标Y'])
              //地图重置完成消息
              break
            case 'ResetMap':
              //地图重置完成消息
              break
            case 'changeTheme':
              //地图主题切换消息
              break
            case 'Clear':
              //地图清空消息
              break
            // 筛选
            case 'DrawGraphic':
              /// name: 需要查询的图层, 即ShowData 命令中 name的值
              bridge
                .QueryTask({
                  name: '学校',
                  returnGeometry: true,
                  geometry: arg.data
                })
                .then(function (d) {
                  console.log(JSON.stringify(d))
                })
              break
          }
        })
        this.mapClick('zoom')
      } catch (error) {
        console.log(error)
      }
    },
    //地图图例事件
    mapClick(name) {
      if (this.mapClickActive.includes(name)) {
        this.mapClickActive.splice(this.mapClickActive.indexOf(name), 1)
        if (name == 'camera') {
          this.clearSingleFun('video_layer')
        } else if (name == 'building') {
          this.clearSingleFun('building_layer')
        } else if (name == 'water') {
          this.alarmType = []
          this.alarmActive = null
          this.clearSingleFun('alarm_layer')
        } else if (name == 'zoom') {
          this.cdmap.Invoke({
            ActionName: 'ChangeMapTheme',
            Parameters: {
              mapthemeid: 'basetheme'
            }
          })
        }
      } else {
        this.mapClickActive.push(name)
        if (name == 'camera') {
          this.fetchDevicesFun()
        } else if (name == 'water') {
          this.fetchAlarmTypeFun()
        } else if (name == 'building') {
          this.getBuildingsFun()
        } else if (name == 'zoom') {
          this.cdmap.Invoke({
            ActionName: 'ChangeMapTheme',
            Parameters: {
              mapthemeid: 'yaogantheme'
            }
          })
        } else if (name == 'close') {
          this.mapClickActive = []
          this.alarmType = []
          this.alarmActive = null
          this.cdmap.Invoke({
            ActionName: 'ResetMap'
          })
        }
      }
    },
    //报警撒点事件
    async mapAlarmPoint(data, idx) {
      this.alarmActive = idx
      console.log('data: ', `${Number(data.lng)}, ${Number(data.lat)}`)
      const res = COORD[`${Number(data.lng)}, ${Number(data.lat)}`]
      console.log('res: ', res)
      // let res = await turnXY({ lng: Number(data.lng), lat: Number(data.lat) })
      const fontName = 'alarm_layer'
      const rendererData = {
        label: '视频设备',
        url: warnIcon,
        width: '53px',
        height: '74px',
        fields: ['#.locDescription']
      }
      const pointData = [
        {
          x: res[0][0],
          y: res[0][1],
          comm_name: data.comm_name,
          id: data.id,
          type: data.type
        }
      ]
      console.log('pointData: ', pointData)
      this.setPointFun(pointData, fontName, rendererData)
    },
    //获取设备点数据
    async fetchDevicesFun() {
      const { data } = await fetchDevices({ comm_code: this.commCode })
      const fontName = 'video_layer'
      const rendererData = {
        label: '视频设备',
        url: videoIcon,
        width: '17.5px',
        height: '28px',
        fields: ['#.locDescription']
      }
      this.allEquipmentData = data
      this.setPointFun(data, fontName, rendererData)
    },
    //获取报警点数据
    async fetchAlarmTypeFun() {
      this.$message('报警数据加载中')
      const data = [
        {
          comm_name: '马桥居委',
          lng: '121.36565900',
          lat: '31.02763500',
          type: '七天未出入',
          id: 6052738
        },
        {
          comm_name: '银康苑',
          lng: '121.38701400',
          lat: '31.02061100',
          type: '门未关',
          id: 6053236
        },
        {
          comm_name: '银康苑',
          lng: '121.38666000',
          lat: '31.02051000',
          type: '高空抛物',
          id: 6053191
        },
        {
          comm_name: '保利佳苑',
          lng: '121.37871700',
          lat: '31.01952400',
          type: '非机动车进楼道',
          id: 5868297
        },
        {
          comm_name: '银康苑',
          lng: '121.38824800',
          lat: '31.02237600',
          type: '群租可能',
          id: 6052609
        },
        {
          comm_name: '保利佳苑',
          lng: '121.37917900',
          lat: '31.01892700',
          type: '异常出入',
          id: 6052898
        },
        {
          comm_name: '华银坊',
          lng: '121.39169100',
          lat: '31.02246100',
          type: '独居老人',
          id: 6052607
        },
        {
          comm_name: '飞碟苑',
          lng: '121.39283752',
          lat: '31.03017616',
          type: '轨迹异常',
          id: 5450290
        },
        {
          comm_name: '银春苑',
          lng: '121.38912300',
          lat: '31.02331400',
          type: '租户变更',
          id: 5431990
        },
        {
          comm_name: '马桥居委',
          lng: '121.36565900',
          lat: '31.02914300',
          type: '热点住户',
          id: 5431996
        },
        {
          comm_name: '马桥居委',
          lng: '121.36565900',
          lat: '31.02751100',
          type: '可疑迁出',
          id: 5433839
        },
        {
          comm_name: '银康苑',
          lng: '121.38924600',
          lat: '31.02172300',
          type: '非法进入',
          id: 5461085
        },
        {
          comm_name: '景城馨苑',
          lng: '121.37075900',
          lat: '31.01645500',
          type: '陌生人预警',
          id: 4624255
        },
        {
          comm_name: '飞碟苑',
          lng: '121.39204000',
          lat: '31.02873200',
          type: '主干道占用',
          id: 5231550
        },
        {
          comm_name: '景城和苑',
          lng: '121.37337300',
          lat: '31.02051800',
          type: '可疑楼内经营',
          id: 4610621
        }
      ]
      //const { data } = await fetchAlarmType({ comm_code: this.commCode })
      this.alarmType = filter(data, ({ type }) => includes(ALARM_TYPE, type))
    },
    //获取楼号数据
    async getBuildingsFun() {
      let { data } = await fetchBuildings({ comm_code: this.commCode })
      const fontName = 'building_layer'
      const rendererData = {
        label: '楼号',
        url: houseIcon,
        width: '26.5px',
        height: '37px',
        fields: ['#.locDescription']
      }
      this.setPointFun(data, fontName, rendererData)
    },
    //通用撒点
    setPointFun(data, fontName, rendererData) {
      this.cdmap.Invoke({
        ActionName: 'ShowData',
        Parameters: {
          name: fontName,
          data: {
            content: data,
            parsedata: 'function(d){return d}',
            parsegeometry: 'function(item){return {x:item.x,y:item.y}}'
          },
          legendVisible: false,
          popupEnabled: false,
          isLocate: false, //地图是否自适应
          renderer: {
            type: 'simple',
            label: rendererData.label,
            visualVariables: [],
            symbol: {
              type: 'picture-marker',
              url: rendererData.url,
              width: rendererData.width,
              height: rendererData.height
            }
          },
          // "maxScale":100,
          // "minScale":5000,
          labels: [
            {
              fields: rendererData.fields,
              type: 'text',
              color: [246, 83, 104, 1],
              font: {
                family: 'Arial Unicode MS',
                size: 22,
                weight: 'bold'
              },
              haloColor: [255, 255, 255, 1],
              haloSize: 1,
              maxScale: 100,
              minScale: 9999,
              labelPlacement: fontName == 'building_layer' ? 'above-center' : 'below-center'
            }
          ]
        }
      })
    },
    //通用画面
    setAreaFun(data) {
      let sgFilter = COMMUNITY_AREA.filter(d => d.label == data)
      this.cdmap.Invoke({
        ActionName: 'ShowData',
        Parameters: {
          name: 'community_layer',
          type: 'polygon',
          legendVisible: false,
          popupEnabled: false,
          data: {
            content: [
              {
                attributes: { 小区: data },
                geometry: {
                  rings: sgFilter[0].data
                }
              }
            ],
            parsedata: 'function(d){return d}',
            parsegeometry: 'function(item){return {rings:item.geometry.rings}}'
          },
          renderer: {
            type: 'simple',
            label: '小区范围',
            visualVariables: [],
            symbol: {
              type: 'polygon-3d',
              symbolLayers: [
                {
                  type: 'fill',
                  material: {
                    color: 'rgba(252,173,7, 0.5)'
                  },
                  outline: {
                    color: [252, 173, 7, 0.9],
                    size: 1
                  }
                }
              ]
            }
          }
        }
      })
    },
    //清空单个标点
    clearSingleFun(name) {
      this.cdmap.Invoke({
        //视频撒点
        ActionName: 'ShowData',
        Parameters: {
          name: name,
          mode: 'delete'
        }
      })
    },

    //弹窗事件
    //报警弹窗
    async alarmDialogFun() {
      this.warningDialogVisible = true
      this.alarmDetail = JSON.parse(JSON.stringify(this.argData))
    },
    //视频弹窗
    async videoDialogFun(moreData) {
      if (!this.alreadyStop) {
        this.stopVideoFun()
      }
      this.$message('正在拉取视频,请耐心等待几秒钟')
      this.selectVideoPart = null
      this.videoPartVisible = false
      this.videoTitle = moreData?.ipAddr || this.argData.locDescription
      this.videoDialogVisible = true
      let baseIp = moreData?.ipAddr || this.argData.ipAddr
      this.selectVideoPartIp = baseIp
      const ip1 = 'rtsp://admin:admin@'
      const ip2 = ':554/cam/realmonitor?channel=1&subtype=1'
      let ip3 = ip1 + baseIp + ip2
      if (baseIp) {
        if (baseIp.indexOf('192.168.1.') !== -1) {
          //飞碟苑
        } else if (baseIp.indexOf('192.168.17.') !== -1) {
          //夏朵
        } else if (baseIp.indexOf('10.50.33.') !== -1) {
          //银春苑
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        } else if (baseIp.indexOf('10.50.23.') !== -1) {
          //银康苑
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        } else if (baseIp.indexOf('192.168.11.') !== -1) {
          //敬南苑
        } else if (baseIp.indexOf('10.50.3.') !== -1) {
          //馨苑
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        } else if (baseIp.indexOf('10.50.13.') !== -1) {
          //佳苑
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        } else if (baseIp.indexOf('10.50.62.') !== -1) {
          //和苑
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        } else if (baseIp.indexOf('10.50.52.') !== -1) {
          //雅苑
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        } else if (baseIp.indexOf('192.168.4.') !== -1) {
          //中西街
        } else if (baseIp.indexOf('192.168.0.') !== -1) {
          //华银坊
        } else if (baseIp.indexOf('192.168.14.') !== -1) {
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        } else if (baseIp.indexOf('10.50.83.') !== -1) {
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        } else if (baseIp.indexOf('10.50.73.') !== -1) {
          ip3 = 'rtsp://admin:fame1234@' + baseIp + ':554/video3'
        }
        this.ip3 = ip3
        const rtspUrl = { uri: ip3 }
        const startUrl = BASEVIDEO + '/start'
        await fetch(startUrl, {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
          },
          body: JSON.stringify(rtspUrl)
        })
          .then(res => {
            if (res.status === 200) {
              return res.json()
            }
          }) //判断res.state == 200 并进行json转换
          .then(data => {
            if (data) {
              const uri = data.uri
              this.videoBaseId = data.id
              this.videoBaseUrl = BASEVIDEO + uri
              this.alreadyStop = false
              this.playVideo()
            }
          })
      }
    },
    //播放
    playVideo() {
      var myPlayer = videojs('base-video')
      myPlayer.src([
        {
          type: 'application/x-mpegURL',
          src: this.videoBaseUrl
        }
      ])
      myPlayer.play()
      this.myRTSPPlayer = myPlayer
    },
    async stopVideo() {
      if (this.myRTSPPlayer) {
        this.videoBaseUrl = ''
        this.stopVideoFun()
      }
    },
    async stopVideoFun() {
      let body = { uri: this.ip3, id: this.videoBaseId }
      let afteruri = BASEVIDEO + '/stop'
      this.alreadyStop = true
      await fetch(afteruri, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(res => {
          if (res.status === 200) {
            return res.json()
          }
        }) //判断res.state == 200 并进行json转换
        .then(data => {
          console.log(data)
        })
    },
    //设备列表
    setEquipmentList(data, fontName, rendererData) {
      let arrs = []
      data.forEach(element => {
        arrs.push(element[0])
      })
      this.setPointFun(arrs, fontName, rendererData)
    },
    openEquipmentList() {
      let ipList = this.pointDetailAll.filter(d => d[0].posX == this.argData.posX)
      this.ipAddrArrs = ipList[0]
      if (this.ipAddrArrs.length > 1) {
        this.equipmentDialogVisible = true
      } else {
        this.videoDialogFun(this.ipAddrArrs[0])
      }
    },
    showVideo(data) {
      this.videoDialogFun(data)
    },
    //搜索设备位置并移动中心显示
    anchorPoint(e) {
      let selectData = this.allEquipmentData.filter(d => d.ipAddr == e)
      this.changeMapCenter(selectData[0].x, selectData[0].y)
    },
    //更新中心点
    changeMapCenter(sg_x, sg_y) {
      this.cdmap.Invoke({
        ActionName: 'goToPosition',
        Parameters: {
          positon: {
            x: sg_x,
            y: sg_y
          },
          heading: 0,
          tilt: 0, // 倾斜角度,不倾斜设置为0,范围:0-90
          hasImg: false,
          zoom: 12, // 缩放级别,范围:0-11
          isRotation360: false
        }
      })
    },
    //收藏
    async setVedioMarkFun() {
      let res = await setVedioMark({
        id: this.selectVideoPart,
        vedioAddress: this.selectVideoPartIp
      })
      if (res.result == 0) {
        this.$message({ type: 'success', message: '定位成功！' })
        let videoMarkArr = JSON.parse(this.$store.state.videoMark)
        videoMarkArr[this.selectVideoPart - 1] = this.selectVideoPartIp
        this.$store.state.videoMark = JSON.stringify(videoMarkArr)
        this.$store.state.changeVideoMark = JSON.stringify({
          ip: this.selectVideoPartIp,
          key: this.selectVideoPart - 1
        })
        this.videoPartVisible = false
      } else {
        this.$message({ type: 'error', message: '定位失败，请重试！' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.map-area {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}
#mapBox {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}
.equipment-search {
  position: absolute;
  top: 180px;
  right: 1570px;
}
.video-dialog {
  width: 100%;
  height: 290px;
  position: relative;
  .video-dialog-title {
    font-size: 14px;
    line-height: 30px;
  }
  ::v-deep .el-radio {
    color: #fff;
    font-size: 12px;
    line-height: 24px;
  }
  .sure {
    width: 60px;
    height: 22px;
    line-height: 22px;
    box-shadow: 0 0 10px #00fffb inset;
    color: #00fffb;
    border-radius: 5px;
    text-align: center;
    float: right;
    cursor: pointer;
    &.mr-10 {
      margin-right: 10px;
    }
  }
  .subscribe {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding: 5px 6px 0;
    box-shadow: 0 0 10px #00fffb inset;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 2;
    img {
      width: 18px;
    }
  }
}
.equipment-dialog {
  width: 100%;
  height: 500px;
  box-sizing: border-box;
  padding: 20px 0;
  overflow: auto;
  .ipAddr {
    cursor: pointer;
    text-align: center;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(to bottom, #46bcff, rgba(70, 188, 255, 0));
    color: #fff;
    margin-bottom: 10px;
  }
}
.map-background {
  background-image: linear-gradient(270deg, #020e13 0%, rgba(6, 29, 49, 0.37) 100%, #021217 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden !important;
  flex: auto !important;
  flex-direction: column !important;

  .sky-space-bg {
    position: absolute;
    top: 36vh;
    left: 42vw;
    height: 16vw;
    width: 16vw;
  }
  .sky-bg-stars {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-image: radial-gradient(1px 2px at 50px 50px, #eee, rgba(0, 0, 0, 0)),
      radial-gradient(2px 3px at 20px 35px, #fff, rgba(0, 0, 0, 0)),
      radial-gradient(3px 3px at 60px 20px, #ddd, rgba(0, 0, 0, 0));
    background-repeat: repeat;
    background-size: 200px 200px;
    opacity: 0.2;
    animation: opacity 8s infinite;
  }
  .sky-bg-stars:nth-child(1) {
    background-position: 0% 90%;
    animation-delay: 0s;
  }
  .sky-bg-stars:nth-child(2) {
    background-position: 50% 10%;
    animation-delay: 0.6s;
  }
  .sky-bg-stars:nth-child(3) {
    background-position: 40% -80%;
    background-size: 120px 200px;
    animation-delay: 1.8s;
  }
  .sky-bg-stars:nth-child(4) {
    background-position: 150% -80%;
    background-size: 220px 100px;
    animation-delay: 3.2s;
  }
  .sky-space,
  .sky-stars {
    position: absolute;
    top: 0;
    right: 1vw;
    bottom: 0;
    left: 1vw;
    overflow: hidden;
  }
  .sky-stars {
    background-image: radial-gradient(
        2px 2px at 50px 200px,
        rgba(255, 255, 255, 0.1),
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(3px 3px at 40px 60px, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0)),
      radial-gradient(4px 5px at 100px 30px, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0));
    background-repeat: repeat;
    background-size: 380px 380px;
    opacity: 0;
    animation-name: zoom;
    animation-delay: 0s;
    animation-duration: 10s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
  .sky-stars:nth-child(1) {
    top: 20vh;
    bottom: 20vh;
    left: 10vw;
    right: 10vw;
    background-size: 120px 120px;
    background-position: 10% 90%;
  }
  .sky-stars:nth-child(2) {
    background-position: 20% 50%;
    animation-delay: 0.3s;
  }
  .sky-stars:nth-child(3) {
    background-position: 40% 20%;
    animation-delay: 1.3s;
  }
  .sky-stars:nth-child(4) {
    background-position: 50% 10%;
    background-size: 200px 200px;
    transform: rotate(60deg);
    animation-delay: 2.1s;
  }
  .sky-stars:nth-child(5) {
    background-position: 30% 30%;
    background-size: 120px 270px;
    animation-delay: 3s;
  }
  .sky-stars:nth-child(6) {
    background-position: 50% 20%;
    animation-delay: 5.5s;
  }
}
@keyframes opacity {
  0% {
    opacity: 0.2;
    transform: rotate(-5deg);
    animation-timing-function: ease-in;
  }
  50% {
    opacity: 0.8;
    transform: rotate(-13deg);
    animation-timing-function: ease-in;
  }
  100% {
    opacity: 0.1;
    transform: rotate(-20deg);
    animation-timing-function: ease-in;
  }
}
@keyframes zoom {
  0% {
    opacity: 0.02;
    transform: scale(0.1);
    transform: rotate(-20deg);
    animation-timing-function: ease-in;
  }
  5% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.3;
    transform: scale(1.8);
  }
  100% {
    opacity: 0.1;
    transform: scale(2.2);
  }
}
</style>
